import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

interface Props {
  onClick?: () => void;
  sx?: SxProps;
}

export const PoweredByStravaIcon: FC<Props> = ({ onClick, sx }) => (
  <Box onClick={onClick} sx={sx}>
    <svg
      height="100%"
      viewBox="0 0 81 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Powered by Strava</title>
      <path
        d="M7.75 26.6801C6.28891 26.6905 4.83501 26.4746 3.44 26.0401C2.17307 25.6493 1.00149 24.9988 0 24.1301L2.7 20.9201C3.47241 21.5287 4.35042 21.9898 5.29 22.2801C6.16495 22.5487 7.07474 22.6869 7.99 22.6901C8.33216 22.7147 8.67522 22.6564 8.99 22.5201C9.07874 22.4773 9.15381 22.4107 9.2068 22.3277C9.25979 22.2447 9.2886 22.1485 9.29 22.0501C9.27788 21.9268 9.22968 21.8098 9.15142 21.7137C9.07316 21.6177 8.96831 21.5468 8.85 21.5101C8.31844 21.2992 7.76502 21.1482 7.2 21.0601C6.35333 20.8867 5.54333 20.6834 4.77 20.4501C4.06767 20.2456 3.39524 19.9497 2.77 19.5701C2.18802 19.2204 1.69779 18.7371 1.34 18.1601C0.979719 17.5565 0.79931 16.8626 0.82 16.1601C0.814185 15.4709 0.957458 14.7887 1.24 14.1601C1.5231 13.5345 1.94351 12.9808 2.47 12.5401C3.05964 12.0528 3.73914 11.6858 4.47 11.4601C5.36061 11.1869 6.28857 11.0553 7.22 11.0701C8.54076 11.0317 9.85955 11.197 11.13 11.5601C12.2172 11.8868 13.2344 12.4123 14.13 13.1101L11.67 16.5201C10.9792 16.0174 10.2083 15.6354 9.39 15.3901C8.65804 15.1614 7.89678 15.0402 7.13 15.0301C6.84295 15.0091 6.55569 15.0679 6.3 15.2001C6.21844 15.2436 6.15024 15.3084 6.10268 15.3877C6.05512 15.4669 6.03 15.5576 6.03 15.6501C6.0422 15.7666 6.0872 15.8773 6.15976 15.9694C6.23233 16.0614 6.3295 16.131 6.44 16.1701C6.95357 16.3831 7.49069 16.5341 8.04 16.6201C8.93173 16.7731 9.81335 16.9802 10.68 17.2401C11.3879 17.4569 12.0613 17.7734 12.68 18.1801C13.2065 18.5362 13.6444 19.0083 13.96 19.5601C14.2934 20.1721 14.4525 20.8639 14.42 21.5601C14.4314 22.3013 14.2669 23.0347 13.94 23.7001C13.624 24.3332 13.1653 24.8844 12.6 25.3101C11.9706 25.7687 11.2621 26.1077 10.51 26.3101C9.61211 26.5632 8.68286 26.6878 7.75 26.6801Z"
        fill="#999999"
      />
      <path
        d="M18.59 15.56H14.13V11.3H28.13V15.56H23.64V26.4H18.59V15.56Z"
        fill="#999999"
      />
      <path
        d="M28.8 11.3001H36.13C37.2579 11.2663 38.3835 11.4218 39.46 11.7601C40.2452 12.0181 40.9627 12.4487 41.56 13.0201C41.9943 13.4641 42.3345 13.9913 42.56 14.5701C42.8002 15.2091 42.9189 15.8874 42.91 16.5701C42.9417 17.564 42.662 18.5429 42.11 19.3701C41.5539 20.1534 40.8023 20.7775 39.93 21.1801L43.45 26.3201H37.77L34.92 22.0001H33.85V26.3201H28.8V11.3001ZM36.03 18.4901C36.5331 18.5177 37.0316 18.3808 37.45 18.1001C37.6182 17.9762 37.7536 17.8129 37.8442 17.6247C37.9349 17.4364 37.978 17.2288 37.97 17.0201C37.9844 16.809 37.9441 16.5978 37.853 16.4068C37.7619 16.2159 37.6231 16.0517 37.45 15.9301C37.0304 15.6714 36.5423 15.5459 36.05 15.5701H33.85V18.5701H36.03V18.4901Z"
        fill="#999999"
      />
      <path
        d="M72.74 19.9L76.04 26.4H80.88L72.74 10.35L64.61 26.4H69.45L72.74 19.9Z"
        fill="#999999"
      />
      <path
        d="M50.11 19.9L53.4 26.4H58.24L50.11 10.35L41.98 26.4H46.82L50.11 19.9Z"
        fill="#999999"
      />
      <path
        d="M61.43 17.79L58.14 11.3H53.3L61.43 27.35L69.56 11.3H64.72L61.43 17.79Z"
        fill="#999999"
      />
      <path
        d="M0.679993 5.71009V0.110087H2.91999C3.42011 0.08198 3.91258 0.242565 4.29999 0.560087C4.47849 0.72447 4.61854 0.926202 4.71015 1.15091C4.80176 1.37561 4.84268 1.61776 4.82999 1.86009C4.84225 2.09759 4.80302 2.33494 4.715 2.55587C4.62697 2.7768 4.49222 2.97609 4.31999 3.14009C3.93294 3.4672 3.43614 3.63518 2.92999 3.61009H1.62999V5.69009H0.679993V5.71009ZM1.61999 2.71009H2.89999C3.14058 2.74565 3.38588 2.69233 3.58999 2.56009C3.67964 2.48011 3.75038 2.38119 3.79708 2.2705C3.84378 2.15981 3.86527 2.04011 3.85999 1.92009C3.87028 1.79269 3.85027 1.66467 3.80161 1.54648C3.75295 1.42829 3.677 1.3233 3.57999 1.24009C3.38142 1.09097 3.13823 1.01343 2.88999 1.02009H1.62999V2.76009L1.61999 2.71009Z"
        fill="#999999"
      />
      <path
        d="M9.12997 5.67007C8.55061 5.89261 7.90934 5.89261 7.32997 5.67007C7.0151 5.54596 6.73261 5.3518 6.50391 5.1023C6.27521 4.85281 6.1063 4.55453 6.00997 4.23007C5.86995 3.7906 5.8024 3.33126 5.80998 2.87008C5.76465 2.09832 6.01899 1.33886 6.51998 0.750075C6.734 0.51172 6.99625 0.321588 7.28933 0.192286C7.58242 0.0629831 7.89964 -0.00253524 8.21997 7.50382e-05C8.53958 -0.001146 8.85575 0.0660429 9.14725 0.197128C9.43874 0.328214 9.69881 0.52016 9.90998 0.760075C10.411 1.34886 10.6653 2.10832 10.62 2.88007C10.6281 3.34129 10.5605 3.80072 10.42 4.24008C10.315 4.58794 10.13 4.90642 9.87997 5.17007C9.66626 5.38562 9.41114 5.5557 9.12997 5.67007ZM7.19997 4.45008C7.32021 4.60953 7.47582 4.73889 7.65456 4.82797C7.83329 4.91705 8.03027 4.96342 8.22998 4.96342C8.42968 4.96342 8.62666 4.91705 8.80539 4.82797C8.98413 4.73889 9.13974 4.60953 9.25997 4.45008C9.56188 3.98105 9.70233 3.42626 9.65998 2.87008C9.69599 2.34131 9.55144 1.81598 9.24998 1.38007C9.13007 1.23 8.97792 1.10884 8.80481 1.02558C8.6317 0.94231 8.44207 0.899075 8.24997 0.899075C8.05788 0.899075 7.86825 0.94231 7.69514 1.02558C7.52203 1.10884 7.36988 1.23 7.24997 1.38007C6.94998 1.8129 6.80546 2.33458 6.83997 2.86008C6.79835 3.42072 6.94243 3.97947 7.24997 4.45008H7.19997Z"
        fill="#999999"
      />
      <path
        d="M12.83 5.71005L11.76 0.110046H12.76L13.52 4.05005L14.83 0.110046H15.37L16.69 4.05005L17.45 0.110046H18.39L17.32 5.71005H16.32L15.13 1.87005L13.83 5.71005H12.83Z"
        fill="#999999"
      />
      <path
        d="M19.96 5.71005V0.110046H23.45V0.980046H20.91V2.42005H23.23V3.26005H20.91V4.84005H23.45V5.71005H19.96Z"
        fill="#999999"
      />
      <path
        d="M25.21 5.71008V0.110084H27.44C27.9435 0.0794256 28.44 0.24016 28.83 0.560084C29.0057 0.721911 29.1434 0.920583 29.2333 1.14187C29.3232 1.36316 29.3631 1.60159 29.35 1.84008C29.3646 2.22237 29.2442 2.5976 29.01 2.90008C28.7767 3.18396 28.4573 3.38406 28.1 3.47008L29.31 5.70008H28.31L27.13 3.56008H26.13V5.71008H25.21ZM26.15 2.71008H27.37C27.497 2.73311 27.6275 2.72694 27.7516 2.69204C27.8758 2.65714 27.9905 2.59444 28.0868 2.50868C28.1832 2.42291 28.2588 2.31634 28.3078 2.19704C28.3569 2.07773 28.3782 1.94883 28.37 1.82008C28.3782 1.69134 28.3569 1.56243 28.3078 1.44313C28.2588 1.32383 28.1832 1.21725 28.0868 1.13149C27.9905 1.04573 27.8758 0.983024 27.7516 0.948129C27.6275 0.913233 27.497 0.907062 27.37 0.930084H26.13V2.71008H26.15Z"
        fill="#999999"
      />
      <path
        d="M30.91 5.71005V0.110046H34.4V0.980046H31.86V2.42005H34.13V3.26005H31.86V4.84005H34.4V5.71005H30.91Z"
        fill="#999999"
      />
      <path
        d="M36.13 5.71005V0.110052H37.96C38.268 0.109399 38.5734 0.167082 38.86 0.280051C39.1468 0.394936 39.4084 0.564789 39.63 0.780052C39.878 1.02766 40.0661 1.32865 40.18 1.66005C40.3204 2.06153 40.3881 2.48481 40.38 2.91005C40.3892 3.36558 40.3181 3.81918 40.17 4.25005C40.0616 4.58885 39.8649 4.89263 39.6 5.13005C39.371 5.32832 39.1063 5.48105 38.82 5.58005C38.5233 5.67771 38.2123 5.72503 37.9 5.72005H36.13V5.71005ZM37.13 4.84005H37.93C38.1311 4.85199 38.3325 4.82069 38.5205 4.74824C38.7086 4.67579 38.8789 4.56388 39.02 4.42005C39.3251 3.97894 39.4633 3.44372 39.41 2.91005C39.4499 2.39632 39.3122 1.88447 39.02 1.46005C38.899 1.31199 38.7464 1.19292 38.5733 1.11159C38.4003 1.03026 38.2112 0.988728 38.02 0.990052H37.13V4.85005V4.84005Z"
        fill="#999999"
      />
      <path
        d="M44.62 5.71007V0.110074H46.92C47.1416 0.0998995 47.3629 0.134506 47.5708 0.211824C47.7787 0.289143 47.9689 0.407586 48.13 0.560074C48.2643 0.689947 48.3715 0.845234 48.4454 1.01691C48.5192 1.18858 48.5581 1.37321 48.56 1.56007C48.566 1.78336 48.508 2.00371 48.3928 2.19509C48.2777 2.38647 48.1101 2.54088 47.91 2.64007C48.146 2.74896 48.3519 2.91371 48.51 3.12007C48.6836 3.35728 48.7718 3.64634 48.76 3.94007C48.76 5.08007 48.0733 5.65007 46.7 5.65007H44.6L44.62 5.71007ZM45.56 2.41007H46.79C47.0115 2.42603 47.2314 2.36218 47.41 2.23007C47.484 2.16599 47.5423 2.0858 47.5804 1.99565C47.6186 1.9055 47.6355 1.8078 47.63 1.71007C47.6371 1.60923 47.621 1.50809 47.5828 1.41446C47.5447 1.32083 47.4856 1.23722 47.41 1.17007C47.231 1.03876 47.0114 0.974997 46.79 0.990074H45.56V2.41007ZM45.56 4.84007H46.96C47.0751 4.83166 47.189 4.81156 47.3 4.78007C47.4027 4.75514 47.4984 4.70728 47.58 4.64007C47.6537 4.56971 47.7116 4.4845 47.75 4.39007C47.7991 4.26604 47.8229 4.13344 47.82 4.00007C47.8368 3.87335 47.8165 3.74446 47.7614 3.62907C47.7064 3.51368 47.619 3.41676 47.51 3.35007C47.194 3.21825 46.8513 3.16341 46.51 3.19007H45.51V4.84007H45.56Z"
        fill="#999999"
      />
      <path
        d="M51.36 5.71005V3.35005L49.53 0.110046H50.53L51.81 2.49005L53.13 0.110046H54.13L52.3 3.35005V5.71005H51.36Z"
        fill="#999999"
      />
    </svg>
  </Box>
);
